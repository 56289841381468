// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider }  from "firebase/app-check"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAZsPKPT_JWfCHU0o0Hqu0YtMTrC2-yYnE",
  authDomain: "nagymelanilawyer.firebaseapp.com",
  projectId: "nagymelanilawyer",
  storageBucket: "nagymelanilawyer.appspot.com",
  messagingSenderId: "359794167986",
  appId: "1:359794167986:web:6668cde8d7638b6d24f9ab",
  measurementId: "G-VFHRPFPNPF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
const db = getFirestore(app);
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lf_0tgkAAAAALY7wpH6G3qG8HEIaWKtnj3PhRsN'),
  isTokenAutoRefreshEnabled: true
})

export { db }
