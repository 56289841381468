import React from 'react'
import './popup.scss'

interface IProps {
  text: string
  onConfirm: () => void
  isEnglish: boolean
}

const Popup = ({text, onConfirm, isEnglish}: IProps) => {
  const handleClick = () => {
    onConfirm()
  }
  return (
    <div className='popup'>
      <p className='popup__text'>{text}</p>
      <button className='btn btn--primary' onClick={handleClick}>{isEnglish ? 'Confirm' : 'Rendben'}</button>
    </div>
  )
}

export default Popup
