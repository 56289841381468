import { useNavigate } from 'react-router-dom'
import './banner.scss'
interface IProps {
  isEnglish: boolean
}
const Banner = ({isEnglish}: IProps) => {
  const navigate = useNavigate()
  const handleBookAppointmentClick = () => {
    navigate('/appointments')
  }
  return (
    <div className='banner'>
      <div className="container">
        <span className='title'>{isEnglish ?
          "Let's start working together, book an appointment!" :
          'Kezdjük meg a közös munkát, foglaljon időpontot!'}</span>
        <button className='btn btn--primary' onClick={handleBookAppointmentClick}>
          <p className='btn-text'>{isEnglish ? 'Book an appointment' : 'Időpontfoglalás'}</p>
          <img className='btn-arrow' src="./icons/arrow.svg" alt="" />
        </button>
      </div>
    </div>
  )
}

export default Banner
