import React from 'react'
import { useNavigate } from 'react-router-dom'

import './header.scss'
interface IProps {
  isEnglish: boolean
}
const Header = ({isEnglish}: IProps) => {
  const navigate = useNavigate()
  const handleBookAppointmentClick = () => {
    navigate('/appointments')
  }
  return (
    <div className='header'>
      <div className='header__bg'>
        <img className='bg-image' src="./images/header-bg.webp" alt="Dr. Nagy Melánia ügyvéd Pécs"  loading="lazy"/>
      </div>
      <div className='container'>
        <div className="texts-wrapper">
          {isEnglish ?
            <h1>Dr. Melánia Nagy<br /> <strong>lawyer</strong></h1> :
            <h1>Dr. Nagy Melánia<br /> <strong>ügyvéd</strong></h1>
          }
          {isEnglish ?
            <h2>Pécs, Komló and surroundings</h2> :
            <h2>Pécs, Komló és környéke</h2>
          }
          {isEnglish ? 
            <div className='header__text'>
              If you have legal issues and there is a need for assistance, do not hesitate to contact me via the
              below mentioned information.
            </div> :
            <div className='header__text'>
              Ha segítségre van szüksége, keressen bizalommal!
            </div>
          }
          <button className='btn btn--outlined-primary' onClick={handleBookAppointmentClick}>
            <p className="btn-text">{isEnglish ? 'Book an appointment' : 'Időpontfoglalás'}</p>
            <img className='btn-arrow' src="./icons/arrow.svg" alt="" />
          </button>
        </div>
        <div className='header__scroll'>
          <img className='header__arrow' src="./icons/scroll.svg" alt="" />
        </div>
      </div>
    </div>
  )
}

export default Header
