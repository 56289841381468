import React from 'react'
import './list-with-cards.scss'

interface IProps {
  title: string
  list: {
    title: string
    text?: string
    icon?: string
  }[]
}
const LisctWithCards = ({title, list}: IProps) => {
  return (
    <div className='list-with-cards' id='services'>
      <div className="bg-line"></div>
      <div className="container">
        <h2 className="title">{title}</h2>

        <div className="list">
          {list.map(card => (
            <div className="card" key={card.title}>
              <img className='card__icon' src={`./icons/${card.icon}.svg`} alt="" />
              <div className="card__title">{card.title}</div>
            </div>
          )
          )}
        </div>
      </div>
    </div>
  )
}

export default LisctWithCards
