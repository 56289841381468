import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Footer from './components/Footer/footer';
import Navbar from './components/Header/Navbar/navbar';
import AppointmentBooking from './pages/AppointmentBooking/AppointmentBooking';
import Home from './pages/Home/Home';

function App() {
  const [isEnglish, setIsEnglish] = useState(false)
  const handleLangChange = (isEng: boolean) => {
    setIsEnglish(isEng)
    localStorage.setItem('isEnglish', JSON.stringify(isEng))
  }
  useEffect(() => {
    const storedIsEnglish = localStorage.getItem('isEnglish')
    console.log(storedIsEnglish);
    
    if (storedIsEnglish) {
      setIsEnglish(storedIsEnglish === 'true' ? true : false)
    }
    console.log('result', storedIsEnglish === 'true' ? true : false);
  }, [])
  return (
    <div className='App'>
      <Navbar isEnglish={isEnglish} onLangChange={handleLangChange}/>
      <Routes>
        <Route path='/*' element={<Home isEnglish={isEnglish}/>}/>
        <Route path='/appointments' element={<AppointmentBooking isEnglish={isEnglish}/>}/>
      </Routes>
      <Footer isEnglish={isEnglish}/>
    </div>
  );
}

export default App;
