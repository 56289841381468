import {  TextField } from '@mui/material'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useEffect, useState } from 'react'
import { IAppointment } from '../../types'
import './appointment-time-selector.scss'

interface IProps {
  appointment: IAppointment
  bookings: string[]
  isEnglish: boolean
  onBack: () => void
  onSelectTime: (date: Date, time: string) => void
}

const AppointmentTimeSelector = ({appointment, bookings, onBack, onSelectTime, isEnglish}: IProps) => {

  const setNextAvailableDate = () => {
    const today = new Date()
    if (today.getDay() === 5) { //Péntek
      const date = today.getDate()
      today.setDate(date + 4)
      return today
    }
    if (today.getDay() === 6) { //Szombat
      const date = today.getDate()
      today.setDate(date + 3)
      return today
    }
    if (today.getDay() === 0) { //Vasárnap
      const date = today.getDate()
      today.setDate(date + 2)
      return today
    }
    if (today.getDay() === 1) { //Hétfő
      const date = today.getDate()
      today.setDate(date + 1)
      return today
    }
    const date = today.getDate()
    today.setDate(date + 1)
    return today
  }
  
  const [selectedDate, setSelectedDate] = useState<Date>(setNextAvailableDate())
  const [selectedTime, setSelectedTime] = useState<null | string>(null)
  const [availableTimes, setAvailableTimes] = useState<Date[]>([])
  const [selectedDateText, setSelectedDateText] = useState<string>('')

  const daysOfTheWeek = ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat']
  const monthsOfTheYear = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December']

  useEffect(() => {
    setSelectedTime(null)
    onSelectTime(selectedDate, '')
    initTimes()
    setSelectedDateText(() => `${monthsOfTheYear[selectedDate.getMonth()]} ${selectedDate.getUTCDate()}. (${daysOfTheWeek[selectedDate.getDay()]})`)
  }, [selectedDate])

  const initTimes = () => {
    const times: Date[] = []
    for (let i = 0; i < 10; i++) {
      times.push(new Date(selectedDate))
    }
    const firstTime = new Date()
    firstTime.setHours(9)
    firstTime.setMinutes(0)
    const allTimes = times.reduce((times, currTime, index) => {
      if (index > 0) {                
        currTime.setHours(times[index - 1].getHours() + 1)
        currTime.setMinutes(0)
        return [...times, currTime]
      } else {
        return [...times, firstTime]
      }
    }, [] as Date[])
    const selectableTimes = allTimes.filter(time => !bookings.some(booking => booking === time.toISOString().slice(0, 16)))
    
    setAvailableTimes(selectableTimes)
  }

  const handleTimeSelect = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {    
    setSelectedTime((e.target as HTMLDivElement).textContent)
  }

  const handleGoToUserDetails = () => {
    if (!selectedTime) {
      return;
    }
    onSelectTime(selectedDate, selectedTime)
  }

  const formatTime = (time: Date) => {
    return `${time.getHours() < 10 ?
      `0${time.getHours()}` :
      time.getHours()}:${time.getMinutes() < 10  ? `0${time.getMinutes()}` : time.getMinutes()}`
  }
  const disableWeekends = (date: Date) => {
    return new Date(date).getDay() === 0 || new Date(date).getDay() === 1 || new Date(date).getDay() === 6;
  }
  const scrollTo = (sectionName: string) => {
    const section = document.getElementById(`${sectionName}`);
    
    if (!section) {
      return
    }
    setTimeout(() => window.scrollTo({
      top: section.offsetTop - 100,
      left: 0,
      behavior: 'smooth'
    }),100)
  };

  return (
    <div className='appointment-time-selector container'>
      <button className='btn btn--outlined-primary' onClick={() => onBack()}>{isEnglish ? 'Back' : 'Vissza'}</button>
      <div className="appointment-details">
        <div className="title">{appointment.title}</div>
        <div className="duration">{appointment.duration}</div>
        <div className="description">{appointment.description}</div>
      </div>
      <div className="dates">
        <div className="date-selector">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
          <StaticDatePicker 
            openTo="day"
            value={selectedDate}
            disablePast={true}
            shouldDisableDate={disableWeekends}
            onChange={(newValue: any) => {
              if (newValue) {
                setSelectedDate(new Date(newValue));
                scrollTo('available-dates')
              }
            }}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </LocalizationProvider>
        </div>
        <div className="available-dates" id='available-dates'>
          <div className="selected-date">
            <p>{isEnglish ? selectedDate.toDateString() : selectedDateText}</p>
          </div>
          <div className="available-dates__list">
            {availableTimes.map(time => (
              <div
                key={formatTime(time)}
                className={`time ${formatTime(time) === selectedTime ? 'selected' : ''}`}
                onClick={handleTimeSelect}>
                  {formatTime(time)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <button className={`btn btn--primary ${selectedTime ? '' : 'hidden'}`} onClick={handleGoToUserDetails}>{isEnglish ? 'Next' : 'Tovább'}</button>
    </div>
  )
}

export default AppointmentTimeSelector
