import { useState } from 'react'
import Spinner from '../../lib/Spinner/spinner'
import { IAppointment } from '../../types'
import './appointment-list.scss'

interface IProps {
  appointments: IAppointment[]
  isLoading: boolean
  onSelectAppointment: (id: number) => void
  isEnglish: boolean
}

const AppointmentList = ({appointments, isLoading, isEnglish, onSelectAppointment}: IProps) => {
  const [selectedAppointmentId, setSelectedAppointmentId] = useState<null | number>(null)

  const handleClick = (id: number) => {
    
    setSelectedAppointmentId(id)
    onSelectAppointment(id)
  }
  return (
    <>
      <div className="bg-line"></div>
      <div className='appointment-list container'>
        {appointments.map(appointment => {
          return(
            <div className="appointment-card" key={appointment.id}>
              <p className='title'>{appointment.title}</p>
              <button className='btn btn--primary' onClick={() => handleClick(appointment.id)}>
                {isLoading && appointment.id === selectedAppointmentId ? <Spinner /> : (isEnglish ? 'Select' : 'Kiválaszt')}
              </button>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default AppointmentList
