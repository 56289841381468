import React from 'react'
import './footer.scss'
interface IProps {
  isEnglish: boolean
}
const Footer = ({isEnglish}: IProps) => {
  return (
    <div className='footer container'>
      <div className="footer__top">
      <div className="texts">
        <span className='name'>{isEnglish ? 'Dr. Melánia Nagy' : 'Dr. Nagy Melánia'}</span>
        <a className='text' href='https://www.linkedin.com/in/melania-nagy-49939916b'>LinkedIn</a>
        <span className='text'>+36 30 946 9658</span>
        <span className='text'>drnagymelaniaugyved@gmail.com</span>
        <span>{isEnglish ? 'Pécs, Jogász street' : 'Pécs, Jogász utca'}</span>
        <span className='text'>{isEnglish ? '18/B Varoshaz square (1st floor of the so-called „Csille row”)' : 'Komló, Városház tér 18/B.'}</span>
      </div>
      <div className="logo">
        <img src="./images/dnmr-logo_white.png" alt="Nagy Melánia Ügyvéd Pécs" />
      </div>
      </div>
      <div className="footer__disclaimer">
      Ezt a honlapot a Pécsi Ügyvédi Kamarában bejegyzett (a Pécsi Ügyvédi Kamara
      honlapja itt érhető el: <a href="https://www.pecsiugyvedikamara.hu">www.pecsiugyvedikamara.hu</a>)
      dr. Nagy Melánia egyéni ügyvéd

      (kamarai azonosító szám: 36082893) tartja fenn az ügyvédekre vonatkozó
      jogszabályok és belső szabályzatok szerint, melyek az ügyféljogokra vonatkozó
      tájékoztatással együtt a <a href="https://www.mük.hu">Magyar Ügyvédi Kamara</a> valamint
      a <a href="https://www.pecsiugyvedikamara.hu">www.pecsiugyvedikamara.hu</a> honlapokon megtalálhatóak.
      </div>
    </div>
  )
}

export default Footer
