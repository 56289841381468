import Banner from '../../components/Banner/banner'
import Contacts from '../../components/Contacts/contacts'
import BgCircle from '../../components/Decor/bg-circle'
import Header from '../../components/Header/header'
import ListWithCards from '../../components/ListWithCardsComponent/list-with-cards'
import TextComponent from '../../components/TextComponent/text-component'

import './home.scss'
interface IProps {
  isEnglish: boolean
}
const Home = ({isEnglish}: IProps) => {

  const list = [
    {
      title: 'Büntetőjogi védelem',
      icon: 'buntetojog'
    },
    {
      title: 'Polgári ügyek',
      icon: 'polgari'
    },
    {
      title: 'Szerződések',
      icon: 'szerzodes'
    },
    {
      title: 'Családjogi tanácsadás és képviselet',
      icon: 'csaladjog'
    },
    {
      title: 'Házassági bontóperek',
      icon: 'hazassagi'
    },
    {
      title: 'Öröklési és hagyatéki ügyek',
      icon: 'orokles'
    },
    {
      title: 'Cégjog',
      icon: 'cegjog'
    },
    {
      title: 'Munkajog',
      icon: 'munkajog'
    },
    {
      title: 'Szabálysértési jog',
      icon: 'szabalysertes'
    }
  ]
  const listEng = [
    {
      title: 'Criminal defense',
      icon: 'buntetojog'
    },
    {
      title: 'Civil cases',
      icon: 'polgari'
    },
    {
      title: 'Contracts',
      icon: 'szerzodes'
    },
    {
      title: 'Family law counseling and representation',
      icon: 'csaladjog'
    },
    {
      title: 'Divorce proceedings',
      icon: 'hazassagi'
    },
    {
      title: 'Inheritance and estate matters',
      icon: 'orokles'
    },
    {
      title: 'Company law',
      icon: 'cegjog'
    },
    {
      title: 'Labor law',
      icon: 'munkajog'
    },
    {
      title: 'Infringement law',
      icon: 'szabalysertes'
    }
  ]
  return (
    <>
      <BgCircle x='100%' y='50%' r='300'/>
      <BgCircle x='75%' y='90%' r='400'/>
      <BgCircle x='75%' y='300%' r='1000'/>
      <BgCircle x='0%' y='120%' r='300'/>
      <BgCircle x='0%' y='400%' r='1200'/>
      <Header isEnglish={isEnglish}/>
      <TextComponent
        title={isEnglish ? 'Introduction' : 'Bemutatkozás'}
        isEnglish={isEnglish}
      />
      <ListWithCards 
        title={isEnglish ? 'Areas of expertise' : 'Szakterületek, ügyvédi tevékenységek'}
        list={isEnglish ? listEng : list}
      />   
      <Contacts isEnglish={isEnglish}/>
      <Banner isEnglish={isEnglish}/>
    </>
  )
}

export default Home
