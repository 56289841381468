import React, { useEffect, useState } from 'react'
import { IAppointment } from '../../types'
import './appointment-user-details.scss'
import { db } from '../../firebase.config'
import { addDoc, collection } from 'firebase/firestore'
import Spinner from '../../lib/Spinner/spinner'
import emailjs from '@emailjs/browser'
import { scrollToTop } from '../../lib/scrollToTop'

interface IProps {
  appointment: IAppointment
  date: Date
  time: string
  isEnglish: boolean
  onBack: () => void
  onBooking: (message: 'success' | 'failed') => void
}

interface IUserDetails {
  appointmentType: string
  comment: string
  date: string
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
}
interface IErrors {
  appointmentType: boolean
  comment: boolean
  date: boolean
  email: boolean
  firstName: boolean
  lastName: boolean
  phoneNumber: boolean
}

const AppointmentUserDetails = ({onBooking, onBack, appointment, date, time, isEnglish}: IProps) => {
  const [userDetails, setUserDetails] = useState<IUserDetails>({} as IUserDetails)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<IErrors>({} as IErrors)

  useEffect(() => {
    if (Object.values(userDetails).length) {
      validateUserDetails()
    }
  }, [userDetails])

  const daysOfTheWeek = ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat']
  const monthsOfTheYear = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December']
  const formatDate = (date: Date) =>  `${monthsOfTheYear[date.getMonth()]} ${date.getUTCDate()}. (${daysOfTheWeek[date.getDay()]})`

  const appointmentsCollectionRef = collection(db, 'appointments')

  const handleBooking = async () => {
    validateUserDetails()
    if (Object.values(error).length && !Object.values(error).some(el => el)) {
      bookAppointment()
    }
  }

  const validateUserDetails = () => {
    setError({
      ...error,
      firstName: userDetails.firstName ? !userDetails.firstName : true,
      lastName: userDetails.lastName ? !userDetails.lastName : true,
      phoneNumber: userDetails.phoneNumber ? !validatePhoneNumber(userDetails.phoneNumber) : true,
      email: userDetails.email ? !validateEmail(userDetails.email): true
    })
  }

  const validatePhoneNumber = (phoneNumber: string) => {
    const onlyNumbersRegex = /\d/g
    const numbers = phoneNumber.slice(1)
    if (phoneNumber[0] !== '+' && phoneNumber[0] !== '0') {
      return false
    }
    return onlyNumbersRegex.test(numbers) && phoneNumber.length > 9
  }
  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  const bookAppointment = async () => {
    try{
      setIsLoading(true)
      await addDoc(appointmentsCollectionRef, {
        appointmentType: appointment.title,
        comment: userDetails.comment ?? '',
        date: `${date.toISOString().slice(0, 11)}${time}`,
        email: userDetails.email,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        phoneNumber: userDetails.phoneNumber,
      });
      onBooking('success')
      setIsLoading(false)
      sendEmail()
      scrollToTop()
    } catch (err) {
      console.warn('A foglalás nem sikerült');
      onBooking('failed')
    }
  }

  const sendEmail = () => {
    const templateParams = {
      customer_name: `${userDetails.lastName} ${userDetails.firstName}`,
      customer_phone: userDetails.phoneNumber,
      customer_email: userDetails.email,
      booking_date: `${date.toISOString().slice(0, 10)} - ${time}`,
      booking_appointment: appointment.title,
      booking_comment: userDetails.comment
    }
    emailjs.send('booking_email_gmail', 'new_booking', templateParams, 'ps1Ab9mdYE4eeMwip')
  }
 
  return (
    <div className="appointment-user-details-wrapper container">
    <button className='btn btn--outlined-primary' onClick={() => onBack()}>{isEnglish ? 'Back' : 'Vissza'}</button>
    <div className='appointment-user-details'>
      <div className="appointment-details">
        <div className="appointment-details__date">{isEnglish ? date.toDateString() : formatDate(date)}</div>
        <div className="appointment-details__appointment-type">{appointment.title}</div>
        <div className="appointment-details__time">{time}</div>
        <div className="appointment-details__appointment-duration">{appointment.duration}</div>
      </div>
      <div className="user-details">
        <p className="user-details__title">{isEnglish ? 'Data required for booking' : 'Foglaláshoz szükséges adatok'}</p>
        <div className="user-detail">
          <div className="user-detail__title">{isEnglish ? 'Last name' : 'Vezetéknév'}</div>
          <input className={`${error.lastName ? 'error' : ''} cap`} type="text" placeholder={isEnglish ? 'Last name' : 'Vezetéknév'} onChange={(e) => setUserDetails({...userDetails, lastName: e.target.value.toUpperCase()})}/>
          {error.lastName && <p className='user-detail__error'>{isEnglish ? 'Please enter your last name!' : 'Kérem adja meg a vezetéknevét!'}</p>}
        </div>
        <div className="user-detail">
          <div className="user-detail__title">{isEnglish ? 'First name' : 'Keresztnév'}</div>
          <input className={`${error.firstName ? 'error' : ''} cap`} type="text" placeholder={isEnglish ? 'First name' : 'Keresztnév'} onChange={(e) => setUserDetails({...userDetails, firstName: e.target.value.toUpperCase()})}/>
          {error.firstName && <p className='user-detail__error'>{isEnglish ? 'Please enter your first name!' : 'Kérem adja meg a keresztnevét!'}</p>}
        </div>
        <div className="user-detail">
          <div className="user-detail__title">{isEnglish ? 'Phone number' : 'Telefonszám'}</div>
          <input className={`${error.phoneNumber ? 'error' : ''}`} type="tel" placeholder={isEnglish ? 'Phone number' : 'Telefonszám'} onChange={(e) => setUserDetails({...userDetails, phoneNumber: e.target.value})}/>
          {error.phoneNumber && <p className='user-detail__error'>{isEnglish ? 'Please enter a valid phone number (e.g: +36301234567 vagy 06201234567)!' : 'Kérem adjon meg egy valódi telefonszámot (pl.: +36301234567 vagy 06201234567)!'}</p>}
        </div>
        <div className="user-detail">
          <div className="user-detail__title">Email</div>
          <input className={`${error.email ? 'error' : ''}`} type="email" placeholder={isEnglish ? 'Email address' : 'Email cím'} onChange={(e) => setUserDetails({...userDetails, email: e.target.value})}/>
          {error.email && <p className='user-detail__error'>{isEnglish ? 'Please enter a valid email!' : 'Kérem adjon meg egy helyes email címet!'}</p>}
        </div>
        <div className="user-detail">
          <div className="user-detail__title">{isEnglish ? 'Comment' : 'Megjegyzés'}</div>
          <input type="text"onChange={(e) => setUserDetails({...userDetails, comment: e.target.value})}/>
        </div>
        
        <button className='btn btn--primary' onClick={handleBooking}>
          {isLoading ? <Spinner /> : (isEnglish ? 'Booking' : 'Foglalás')}
        </button>
      </div>
    </div>
    </div>
  )
}

export default AppointmentUserDetails
