import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { scrollToTop } from '../../../lib/scrollToTop'
import './navbar.scss'

interface IProps {
  isEnglish: boolean
  onLangChange: (lang: boolean) => void
}

const Navbar = ({isEnglish, onLangChange}: IProps) => {
  const [isOnTop, setIsOnTop] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const changeBackground = () => {
    if (window.scrollY >= 45) {
      setIsOnTop(false)
    } else {
      setIsOnTop(true)
    }
  }
  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  })
  const scrollTo = (sectionName: string) => {
    const section = document.getElementById(`${sectionName}`);
    if (!section) {
      return
    }
    window.scrollTo({
      top: section.offsetTop - 100,
      left: 0,
      behavior: 'smooth'
    })
  };

  const navigate = useNavigate()
  const location = useLocation()
  
  const handleBookAppointmentClick = () => {
    navigate('/appointments')
    setIsOpen(false)
  }
  const handleLogoClick = () => {
    navigate('/')
    scrollToTop(true)
  }
  const handleNavButtonClick = (button: string) => {
    if (location.pathname !== '/') {
      navigate('/')
    }
    setTimeout(() => {
      scrollTo(button)
      setIsOpen(false)
    }, 100)
  }
  const setLanguage = () => {
    onLangChange(!isEnglish)
  }

  return (
    <div className={`navbar navbar${isOnTop ? '--transparent' : '--colored'}`}>
      <div className="container">
      {isOpen && <div className="overlay" onClick={() => setIsOpen(false)}></div>}
        <div className="logo" onClick={handleLogoClick}>
          <img src="./images/dnmr-logo_white.png" alt="Nagy Melánia Ügyvéd" />
        </div>
        <div className='mobile-menu-icon' onClick={() => setIsOpen(!isOpen)}>
          <div className={`${isOpen ? 'open' : ''}`}></div>
          <div className={`${isOpen ? 'open' : ''}`}></div>
          <div className={`${isOpen ? 'open' : ''}`}></div>
        </div>
        
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
          <nav className="sidebar-buttons">
            <div className="navbar__button" onClick={() => handleNavButtonClick('introduction')}>
              {isEnglish ? 'Introduction' : 'Bemutatkozás'}
            </div>
            <div className="navbar__button" onClick={() => handleNavButtonClick('services')}>
              {isEnglish ? 'Areas of expertise' : 'Szakterületek'}
            </div>
            <div className="navbar__button" onClick={() => handleNavButtonClick('contacts')}>
              {isEnglish ? 'Contacts' : 'Elérhetőségek'}
            </div>
            <div className="navbar__button" onClick={setLanguage}>
              {isEnglish ? 'Magyar' : 'English'}
            </div>
            <button className='btn btn--primary' onClick={handleBookAppointmentClick}>
              <p className='btn-text'>
                {isEnglish ? 'Book an appointment' : 'Időpontfoglalás'}
              </p>
              <img className='btn-arrow' src="./icons/arrow.svg" alt="" />
            </button>
          </nav>
        </div>
        {location.pathname === '/' && <nav className="buttons">
          <div className="navbar__button" onClick={() => scrollTo('introduction')}>
            {isEnglish ? 'Introduction' : 'Bemutatkozás'}
          </div>
          <div className="navbar__button" onClick={() => scrollTo('services')}>
            {isEnglish ? 'Areas of expertise' : 'Szakterületek'}
          </div>
          <div className="navbar__button" onClick={() => scrollTo('contacts')}>
            {isEnglish ? 'Contacts' : 'Elérhetőségek'}
          </div>
          <div className="navbar__button" onClick={setLanguage}>
            {isEnglish ? 'Magyar' : 'English'}
          </div>
          <button className='btn btn--primary' onClick={handleBookAppointmentClick}>
            <p className='btn-text'>
              {isEnglish ? 'Book an appointment' : 'Időpontfoglalás'}
            </p>
            <img className='btn-arrow' src="./icons/arrow.svg" alt="" />
          </button>
        </nav>}
      </div>
    </div>
  )
}

export default Navbar
