import { useEffect, useState } from 'react'
import AppointmentList from '../../components/AppointmentList/appointment-list'
import AppointmentTimeSelector from '../../components/AppointmentTimeSelector/appointment-time-selector'
import AppointmentUserDetails from '../../components/AppointmentUserDetails/appointment-user-details'
import { IAppointment } from '../../types'
import { db } from '../../firebase.config' 
import { collection, getDocs } from 'firebase/firestore'
import { scrollToTop } from '../../lib/scrollToTop'
import Popup from '../../components/Popup/popup'
import { useNavigate } from 'react-router-dom'
interface IProps {
  isEnglish: boolean
}
const AppointmentBooking = ({isEnglish}: IProps) => {
  const [selectedAppointment, setSelectedAppointment] = useState<null| number>(null)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedTime, setSelectedTime] = useState<null | string>(null)
  const [bookings, setBookings] = useState<string[] | null>(null)
  const [bookingStatus, setBookingStatus] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)


  const navigate = useNavigate()
  const appointmentsCollectionRef = collection(db, 'appointments')

  useEffect(() => {
    if (bookings === null) {
      getBookings();
    }
  }, []);

  const getBookings = async () => {
    setIsLoading(true)
    const data = await getDocs(appointmentsCollectionRef);
    const appointments = data.docs.map((doc) => (doc.data().date))
    setBookings(appointments);
    setIsLoading(false)
  };
  
  const appointments: IAppointment[] = [
    {
      id: 10,
      title: 'Büntetőjogi védelem',
    },
    {
      id: 1,
      title: 'Polgári ügyek',
    },
    {
      id: 2,
      title: 'Szerződések',
    },
    {
      id: 3,
      title: 'Családjogi tanácsadás és képviselet',
    },
    {
      id: 4,
      title: 'Házassági bontóperek',
    },
    {
      id: 5,
      title: 'Öröklési és hagyatéki ügyek',
    },
    {
      id: 6,
      title: 'Cégjog',
    },
    {
      id: 7,
      title: 'Munkajog',
    },
    {
      id: 8,
      title: 'Szabálysértési jog',
    },
    {
      id: 9,
      title: 'Egyéb',
    }
  ]
  const appointmentsEng = [
    {
      id: 10,
      title: 'Criminal defense',
    },
    {
      id: 1,
      title: 'Civil cases',
    },
    {
      id: 2,
      title: 'Contracts',
    },
    {
      id: 3,
      title: 'Family law counseling and representation',
    },
    {
      id: 4,
      title: 'Divorce proceedings',
    },
    {
      id: 5,
      title: 'Inheritance and estate matters',
    },
    {
      id: 6,
      title: 'Company law',
    },
    {
      id: 7,
      title: 'Labor law',
    },
    {
      id: 8,
      title: 'Infringement law',
    },
    {
      id: 9,
      title: 'Other',
    }
  ]

  const handleSelect = (id: number) => {
    setSelectedAppointment(id)
    scrollToTop()
  }
  const handleBackClick = () => {
    setSelectedAppointment(null)
    scrollToTop()
  }
  const handleBackToTimeSelectorClick = () => {
    setSelectedTime(null)
    scrollToTop()
  }
  const handleSelectTime = (date: Date, time: string) => {
    setSelectedDate(date)
    setSelectedTime(time)
    if (date && time) {
      scrollToTop()
    }
  }
  const handleBooking = (message: string) => {
    setBookingStatus(message)
  }
  const handleSuccessConfirm = () => {
    navigate('/')
    setBookingStatus(null)
  }
  const handleFailureConfirm = () => {
    setSelectedAppointment(null)
    setSelectedTime(null)
    setSelectedDate(new Date())
    getBookings()
    setBookingStatus(null)
  }
  return (
    <div>
      {!selectedAppointment && <AppointmentList isEnglish={isEnglish} isLoading={isLoading} appointments={isEnglish ? appointmentsEng : appointments} onSelectAppointment={handleSelect}/>}
      {selectedAppointment && !isLoading && !(selectedDate && selectedTime) && <AppointmentTimeSelector isEnglish={isEnglish} bookings={bookings!} onSelectTime={handleSelectTime} onBack={handleBackClick} appointment={isEnglish ? appointmentsEng.find(appointment => appointment.id === selectedAppointment)! : appointments.find(appointment => appointment.id === selectedAppointment)!}/>}
      {(selectedAppointment && selectedDate && selectedTime) && <AppointmentUserDetails isEnglish={isEnglish} onBooking={handleBooking} onBack={handleBackToTimeSelectorClick} date={selectedDate} time={selectedTime} appointment={isEnglish ? appointmentsEng.find(appointment => appointment.id === selectedAppointment)! : appointments.find(appointment => appointment.id === selectedAppointment)!}/>}
      {bookingStatus && <div className='overlay'></div>}
      {bookingStatus === 'success' && <Popup isEnglish={isEnglish} text={isEnglish ? 'Successful booking!' : 'Sikeres foglalás!'} onConfirm={handleSuccessConfirm}/>}
      {bookingStatus === 'failed' && <Popup isEnglish={isEnglish} text={isEnglish ? 'Booking failed, please try again!' : 'A foglalás nem sikerült. Kérem próbálja újra'} onConfirm={handleFailureConfirm}/>}
    </div>
  )
}

export default AppointmentBooking
