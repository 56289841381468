interface IProps {
  x: string
  y: string
  r: string
}

const BgCircle = ({x, y, r}: IProps) => {
  return (
    <svg viewBox="0 0 1000 1000">
      <circle cx={x} cy={y} r={r} stroke="#373347" strokeWidth="2" fill="none" />
    </svg> 
  )
}

export default BgCircle
