import React from 'react'
import './text-component.scss'
interface IProps {
  title: string
  isEnglish: boolean
}

const TextComponent = ({title, isEnglish}: IProps) => {
  return (
    <div className='text-component-wrapper' id='introduction'>
      <div className="bg-color"></div>
      <div className="photo">
            <img className='photo-img' src="./images/dr-nagy-melania.webp" alt="dr. Nagy Melánia ügyvéd Pécs"  loading="lazy"/>
          </div>
      <div className="text-component container">
        <div className="wrapper">
          <span></span>
          <span className='text'>
            <h2 className="title">{title}</h2>
            {isEnglish ? <p>
              I graduated from the Faculty of Law at University of Pecs in 2016. After the period of being a
              trainee lawyer in Baranya county, I started my lawyer's activity in Komló. I had succesfully
              defended my Ph.D. subject with „summa cum laude” qualification in 2020 which followed by
              further education studies (certified both in specialist lawyer for juvenile cases and mediation).
              As an assistant lecturer I am a member of the university's board educating in the field of
              criminal law in both English and Hungarian languages.<br /> <br />
              The main profile of my legal activity is criminal law. I have broad knowledge on crimes
              against property, violent crimes, economic crimes and crimes against public order.<br /> <br />
              Recently I participated in complex criminal matters as a counsel for the defendant and as
              the injured or affected party's legal representative, with that I earned the required experience,
              the professional training and the sufficient routine to be able to provide immediate, reliable
              and effective legal assistance in criminal matters.<br /> <br />
              Besides, real estate law is also in my interests and expertise including editing of contracts
              of real estate sales, gifts. Providing and counter-signing other documents, policy papers and
              contracts related to real estate and conducting land office procedures are also among my
              duties.<br /> <br />
              My experience and practice in several fields of law facilitate that I can represent my clients
              succesfully in litigation matters and in company law, too. I am committed to assist you in
              family law-related cases (eg. accomodation of minors, divorce and the legal separation,
              maintenance obligations, etc.) I can also represent my clients in the area of labour law and
              I have some experience in cases related to illegal immigrant smuggling,use of a forged
              private document.<br /> <br />
              I have gained significant International experience (eg. Georgia, Italy, Lithuania, Romania,
              Turkey, Portugal and Spain) due to the univerity's mobility program providing opportunity
              for practising law in multicultural environment, conducting research activities and providing
              law-related theoretical classroom trainings. This unique opportunity might come handy in the
              near future regarding cross-border crimes.<br /> <br />
              Primarily I do legal representation for my respected Clients in Baranya county (Pecs, Komlo,
              Siklos, Szigetvar) in Somogy county (Kaposvar, Nagyatad, Siofok) and in Tolna county
              (Bonyhad, Paks, Szekszard, Dombovar); however I also represent legal cases before the
              courts of Budapest and from areas nearby Budapest.<br /> <br />
              <strong>If you have legal issues and there is a need for assistance, do not hesitate to contact me via the
              below mentioned information.</strong>
              <br /><br /><br />
              <strong>Dr. Melánia Nagy<br />
              Lawyer, Pécs</strong>
            </p> :
            <p>
              Jogi diplomámat a Pécsi Tudományegyetem Állam- és Jogtudományi Karán szereztem 2016-ban. Ettől
              az évtől kezdődően - jelenleg egyetemi adjunktusként - az egyetem Büntetőjogi Tanszékén
              büntetőjogi tantárgyakat oktatok magyar és angol nyelven. Kutatási tevékenységem keretében,
              büntetőjogi témakörökben számos nemzetközi előadást tartottam Horvátországban, Litvániában,
              Olaszországban, Portugáliában, Romániában, Spanyolországban és Törökországban. Az ügyvédi
              szakvizsga megszerzése előtt „fiatalkorúak ügyeinek szakjogásza” és „mediátor” végzettséget
              szereztem, jelenleg „munkajogi szakjogász” tanulmányokat is folytatok. A Ph.D. fokozatot „summa
              cum laude” minősítéssel vehettem át 2020-ban.<br /> <br /> A jogi tárgyak elméleti oldalának oktatása mellett mindig is vonzott a gyakorlati tapasztalatszerzés,
              így - az ügyvédi pályát illetően - ügyvédjelöltként egy pécsi ügyvédi irodában kezdtem dolgozni.<br /><br />
              A büntetőjogi szakterületen védelmet és képviseletet is ellátok. Nemcsak a szűk kutatási
              területemhez (gyermekek érdekét sértő és családi elleni bűncselekmények) kapcsolódóan, hanem -
              többek között - kábítószerrel kapcsolatos ügyek, közlekedési-és életellenes bűncselekmények,
              garázdaság, lopás, rongálás, csalás, sikkasztás, valamint okirat-hamisítás miatt indult eljárásokban is
              eljárok. Mindemellett polgárjogi (öröklési és hagyatéki -, valamint ingatlanügyek, házassági
              bontóperek) képviseletben és tanácsadásban is segítem a hozzám forduló Ügyfeleket.<br />
              <br />
              <strong>Forduljon hozzám bizalommal!</strong>
              <br /><br /><br />
              <strong>Dr. Nagy Melánia<br />
              Ügyvéd, Pécs</strong>
            </p>
            }
          </span>
        </div>
      </div>
    </div>
  )
}

export default TextComponent
