import './contacts.scss'
interface IProps {
  isEnglish: boolean
}
const Contacts = ({isEnglish}: IProps) => {
  return (
    <div className='contacts container' id='contacts'>
      <h2 className="title">{isEnglish ? 'Contacts' : 'Elérhetőségek'}</h2>
      <div className="contacts__list">
        <div className="contact">
          <span className='contact__icon'>
            <img className='svg' src="./icons/phone.svg" alt="" />
          </span>
          <a href='tel:+36 30 946 9658' className='contact__text'>+36 30 946 9658</a>
        </div>
        <div className="contact">
          <span className='contact__icon'>
          <img className='svg' src="./icons/email.svg" alt="" />
          </span>
          <span className='contact__text'>drnagymelaniaugyved@gmail.com</span>
        </div>
        <div className="contact">
          <span className='contact__icon'>
          <img className='svg' src="./icons/location.svg" alt="" />
          </span>
          <div className='contact__texts'>
            <span className='contact__text'>{isEnglish ? 'Pécs, Jogász street' : 'Pécs, Jogász utca'}</span>
            <span className='contact__text'>{isEnglish ? '18/B Varoshaz square (1st floor of the so-called „Csille row”)' : 'Komló, Városház tér 18/B.'}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacts
